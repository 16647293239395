.vocab-history-table{
    border-collapse: collapse;
}
.vocab-history-table td{
    padding: 0 1rem 0 0.5rem;
    text-align: left;
}
.vocab-history-tr{
    transition: 200ms ease;
    cursor: pointer;
}
.vocab-history-tr:hover{
    background: lightblue;
}

.curr-vocab-container span{
    text-decoration: underline;
    font-weight: bold;
}

.curr-vocab-container p{
    white-space: pre-line;
    text-align: left;
    padding: 1rem;
}