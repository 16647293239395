.most-recent-intro{
    text-align: left;
    font-size: 2.25rem;
}
.most-recent-score-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem 1rem 0 1rem;
    /* background: white; */
    /* border: 1px black solid; */
}
.section-score-div{
    position: relative;
    min-width: 12rem;
    min-height: 9.5rem;
    background: rgb(250, 250, 250);
    border: 2px rgb(216, 216, 216) solid;
    border-radius: 1rem;
    padding: 1rem;
    margin: 0 1rem 1rem 0;
    transition: 300ms ease;
}
.section-score-div:hover{
    background: rgb(248, 248, 248);
    transform: translate(-1px,-1px);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5) ;
}
.score-title-div{
    display: flex;
    vertical-align: center;
}
.score-image{
    width: 2rem;
    aspect-ratio: 1;
}
.score-title{
    font-size: 2rem;
}
.scaled-score-container{
    margin-top: 0.75rem;
    font-size: 2.75rem;
    font-weight: bold;
    text-align: left;
}
.score-see-details{
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    text-decoration: underline;
    font-size: 0.75rem;
    color: rgb(0, 0, 184);
}
.score-date{
    font-size: 1.5rem;
}

@media screen and (max-width: 500px){
    .most-recent-intro{
        font-size: 1.5rem;
    }
    .most-recent-score-container{
        padding: 1rem 0 1rem 0;
    }
    .section-score-div{
        min-width: 8rem;
        min-height: 7rem;
        border-radius: 0.5rem;
        padding: 0.5rem;
    }
    .score-title{
        font-size: 1.25rem;
    }
    .scaled-score-container{
        font-size: 2rem;
        margin-top: 0.25rem;
    }
    .score-date{
        font-size: 1rem;
    }
}