.soft-edge-container {
    width: min(40rem, 90%);
    position: relative;
    display: inline-block;
}

.soft-edge-image {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    box-shadow: 0 0 20px white;
}

.navigation-button-container{
    font-size: 1.2rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, 9rem);
    gap: 1rem;
}

.navigation-button{
    font-weight: bold;
    text-decoration: underline;
    border: 2px black solid;
    border-radius: 0.2rem;
    padding: 0.25rem 0 0.25rem 0;
    transition: 0.3s ease;
}

.navigation-button:hover{
    background: black;
    color: white;
}

.calendar-wrapper{
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.schedule-table{
    text-align: left;
    font-weight: bold;
    font-size: 1.2rem;
}

.schedule-table td{
    padding-bottom: 0.8rem;
}

.schedule-table td:first-child{
    padding-right: 2rem;
}

.color-coding-box {
    width: 10px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    display: inline-block;
    vertical-align: middle; /* For alignment */
}

.section-title-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    background: black;
}

.section-title-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-title{
    font-size: 2rem;
    font-weight: bold;
}

.icon{
    width: 2rem;
    aspect-ratio: 1;
}

.nav-up, .nav-down{
    display: flex;
    align-items: center;
    padding-left: 1rem;
    cursor: pointer;
}
.nav-up-arrow{
    width: 2rem;
    aspect-ratio: 1;
    /* border: 2px red solid; */
}
.nav-down-arrow{
    width: 2rem;
    aspect-ratio: 1;
    rotate: 180deg;
}

.digital-test-screenshot{
    width: min(60rem,90%);
    border: 2px solid black;
}

.grid-list{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(15rem,1fr));
    grid-gap: 10px;
    width: min(80rem, 100%)
}

.test-review-content-container{
    display: inline-block;
    width: min(60rem,90%);
    font-size: 1.2rem;
}

.span-link{
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 490px){
    .calendar-wrapper{
        flex-direction: column;
    }
    .schedule-table{
        font-size: 1rem;
    }
}

@media screen and (max-width: 992px){
    .nav-up span{
        display: none;
    }
    .nav-down span{
        display: none;
    }
}