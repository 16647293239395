.scroll-button-container{
    position: fixed;
    right: 0;
    bottom: 0;
    width: 2rem;
    aspect-ratio: 1;
    background: grey;
    opacity: 50%;
    transition: opacity 1s;
    cursor: pointer;
}
.scroll-button-container:hover{
    opacity: 100%;
    transition: opacity 1s;
}
.scoll-button-icon{
    width: 100%;
    aspect-ratio: 1;
}
.scroll-up-fixed{
    width: 100%;
    aspect-ratio: 1;
}