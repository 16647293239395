.nav-container{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.nav-box{
    display: flex;
    position: relative;
    background: #f8f4e7;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-bottom: 0.5rem;
    cursor: pointer;
}
.spinner{
    content: '';
    position: absolute;
    width: 10%;
    aspect-ratio: 0.1;
    background: linear-gradient(rgb(0, 38, 255), rgb(0, 238, 255));
    /* animation: spin 4s linear infinite; */
}
.nav-box:hover .spinner{
    filter: blur(1px);
    animation: spin 4s linear infinite;
}
.nav-box::after{
    content: '';
    position: absolute;
    inset: 4px;
    background: #f8f4e7;
    border-radius: 10px;
}
@keyframes spin{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.nav-text{
    display: flex;
    flex-wrap: wrap;
}
.nav-text span{
    display: flex;
    position: relative;
    width: max-content;
    margin: 0.5rem 1rem 0.5rem 1rem;
    font-size: 1.1rem;
    text-decoration: none;
    z-index: 10;
    user-select: none;
}

.nav-text span::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: teal;
    transition: width 0.3s ease;
}

.nav-text span:hover::before{
    width: 100%;
}

.nav-icon{
    height: 1.5rem;
    aspect-ratio: 1;
}