.find-homework-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.select-bundle-container{
    height: 35rem;
    overflow-y: scroll;
}
.select-bundle-container::-webkit-scrollbar{
    width: 0.4rem;
}
.select-bundle-container::-webkit-scrollbar-thumb{
    background: #333;
}
.homework-question-container{
    display: flex;
    flex-wrap: wrap;
    border-radius: 1rem;
    border-top: 2px black solid;
    border-bottom: 2px black solid;
}
.homework-question-info{
    width: min(15rem, 100%);
    align-items: center;
}
.homework-image-container{
    position: relative;
    width: min(max(25rem, 40%), 100%);
}
.homework-image-container img{
    width: 100%;
    height: auto;
    pointer-events: none;
    user-select: none;
}
.homework-student-answers{
    width: min(20rem, 100%);
}
.student-answers{
    padding: 1rem;
}