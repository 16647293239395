.std-testing-container{
    display: flex;
    flex-direction: column;
    /* background: linear-gradient(#fec3a6,rgb(230, 230, 230)); */
    background: #f8f4e7;
    position: relative;
    width: 100;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* border: 2px red solid; */
}
.std-testing-title-container{
    display: flex;
    width: 100%;
    background: black;
    color: white;
    justify-content: space-between;
    align-items: center;
}
.std-testing-title{
    display: flex;
}
.std-testing-title h1{
    padding: 0.5rem;
    margin: 0;
}
.std-testing-icon{
    width: 3rem;
    aspect-ratio: 1;
}
.std-testing-content{
    padding: 1rem;
}
.std-testing-wrapper{
    position: relative;
    width: min(100%, 140rem);
    /* border: 2px green solid; */
}
.std-testing-steps{
    display: flex;
    position: relative;
    margin-bottom: 1rem;
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
}
.std-testing-img-container{
    width: 20%;
    /* border: 2px green solid; */
    align-items: center;
    justify-content: center;
}
.std-testing-trapezoid{
    position: relative;
    width: 80%;
    /* background: #ffbd9a; */
    background: #e4e0d4;
    opacity: 0;
    filter: blur(2px);
    /* transform: translateX(50px); */
    /* border: 2px red solid; */
}
.std-testing-trapezoid.right{
    transform: translateX(50px);
    text-align: right;
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%, 20% 0);
}
.std-testing-trapezoid.left{
    transform: translateX(-50px);
    text-align: left;
    clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%, 0 0);
}
.std-testing-wrapper>:nth-child(even)>.std-testing-trapezoid>.std-text-container{
    left: 20%;
}
.std-testing-wrapper>:nth-child(even)>.std-testing-trapezoid>.std-text-container-small-width{
    left: 20%;
    padding: 0.5rem 0.5rem 0rem 0rem;
}
.std-testing-wrapper>:nth-child(odd)>.std-testing-trapezoid>.std-text-container-small-width{
    padding: 0.5rem 0 0rem 0.5rem;
}
.std-testing-img-container img{
    max-height: 100%;
    max-width: 100%;
    aspect-ratio: 1;
}
.std-text-container{
    display: block;
    position: relative;
    width: 80%;
    padding: 1rem;
    /* border: 2px black solid; */
}
.std-text-container h2{
    margin: 0;
    padding: 0 0 0.5rem 0;
}
.std-text-container span{
    display: block;
    margin-bottom: 0.5rem;
}
.std-text-container-small-width{
    display: none;
    position: relative;
    width: 80%;
}
.std-text-container-small-width span{
    display: block;
    margin-bottom: 0.5rem;
}
.std-text-container-small-width h2{
    margin: 0;
    padding: 0 0 0.5rem 0;
}
@media screen and (max-width: 768px){
    .std-text-container-small-width{
        display: block;
    }
    .std-text-container{
        display: none;
    }
}
.std-testing-trapezoid.visible{
    transform: translateX(0);
    opacity: 1;
    filter: none;
    transition: transform 1s ease-in, opacity 1.5s, filter 1.5s; 
}