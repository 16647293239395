.review-questions-grid-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
    gap: 1rem;
}

.review-question-container{
    border-top: 4px solid black;
}

.review-question-container img{
    user-select: none;
    pointer-events: none;
    width: 100%;
}
