.calendar{
    display: block;
    width: 21rem;
    margin: 1rem;
}
.month-title{
    font-size: 1.5rem;
    background: orange;
}
.day{
    font-size: 1rem;
    width: 100%;
    border-collapse: collapse;
    user-select: none;
}
.day tbody tr td{
    text-align: center;
    border: 1px black solid;
    width: calc(100%/7);
}
.dates{
    font-size: 1rem;
    width: 100%;
    border-collapse: collapse;
    user-select: none;
}
.dates tbody tr td{
    text-align: center;
    border: 1px black solid;
    width: calc(100%/7);
    height: 1.5rem;
    transition: 0.5s;
}
.dates tbody tr td:hover{
    background: rgb(0, 42, 255);
    color: white;
    transition: 0.5s;
}
.fill{
    background: rgb(106, 176, 255);
}
.fill-yellow{
    background: rgb(255, 255, 120);
}
.fill-orange{
    background: rgb(255, 198, 112);
}
.fill-green{
    background: rgb(125, 255, 120);
}
.fill-red{
    background: rgb(255, 120, 120);
}
.fill-purple{
    background: violet;
}
.rollover{
    font-size: 0.8rem;
    opacity: 30%;
}