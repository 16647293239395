.get-students-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
    gap: 2rem;
    padding: 0.5rem 0rem 1.5rem 0rem;
}

.get-students-container{
    background-color: lightgray;
    padding: 0 1rem 1rem 1rem;
    height: 65vh;
    overflow-y: scroll;
    overflow-x: scroll;
}

.get-students-container::-webkit-scrollbar{
    width: 0.4rem;
}
.get-students-container::-webkit-scrollbar-thumb{
    background: #333;
}