.homework-page{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.green-circle{
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background: green;
    opacity: 40%;
}

.blue-circle{
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    border: 3px blue solid;
    opacity: 50%;
}

.choose-homework-container{
    padding-bottom: 1rem;
}

.homework-container{
    padding: 1rem 0 1rem 0;
}

.homework-question-image-container{
    width: min(40rem, 100%);
}

.homework-question-image{
    width: 100%;
    pointer-events: none;
    user-select: none;
}

.student-response-container{
    width: min(10rem, 100%);
    /* border: 2px pink solid; */
}

.all-answers-container{
    width: min(30rem, 100%);
    display: grid;
    grid-template-columns: repeat(auto-fit, 6rem);
}

.answer-cell{
    /* border: 2px red solid; */
    height: 2rem;
    text-align: left;
    overflow: hidden;
}

.question-container{
    /* border: 2px blue solid; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border-bottom: 2px black solid;
    border-top: 2px black solid;
    padding: 1rem 0 1rem 0;
}