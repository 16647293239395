.SAT-advanced-list-container{
    padding: 1rem;
    margin: 1rem;
    border: 1px rgb(238, 223, 223) solid;
    box-shadow: 1px 1px 2px grey;
    border-radius: 5px;
    max-height: 30rem;
    max-width: 90rem;
    overflow-y: auto;
}
.SAT-advanced-table,.ACT-advanced-table{
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}
.SAT-advanced-table td{
    width: calc(100%/5);
    border-bottom: 1px black solid;
}
.ACT-advanced-table td{
    width: calc(100%/6);
    border-bottom: 1px black solid;
}
.SAT-advanced-test-selector{
    border-bottom: 1px grey solid;
    height: 2rem;
    transition: 300ms ease;
    cursor: pointer;
}
.SAT-advanced-test-selector:hover{
    background: lightblue;
}
.sort-icon{
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
    cursor: pointer;
}
.sort-icon path{
    transition: 500ms ease;
}
.SAT-advanced-currtest-container{
    padding: 0 2rem 0 2rem;
    text-align: left;
}
.SAT-advanced-student-answers,.DigitalSAT-advanced-student-answers,.ACT-advanced-student-answers{
    overflow-x: auto;
    padding-bottom: 1rem;
}
.SAT-advanced-student-answers table,.DigitalSAT-advanced-student-answers table,.ACT-advanced-student-answers table{
    border-collapse: collapse;
    text-align: center;
}
.SAT-advanced-td,.DigitalSAT-advanced-td{
    min-width: 1.5rem;
    border: 1px solid black;
    transition: 200ms ease;
    font-size: 1rem;
}
.ACT-advanced-td{
    min-width: 1rem;
    border: 1px solid black;
    transition: 200ms ease;
    font-size: 0.75rem;
}
.SAT-advanced-td.even,.DigitalSAT-advanced-td.even,.ACT-advanced-td.even{
    background: rgb(255, 243, 234);
}
/* .SAT-advanced-td.odd,.DigitalSAT-advanced-td.odd,.ACT-advanced-td.odd{
    background: white;
} */
.SAT-advanced-td:hover,.DigitalSAT-advanced-td:hover,.ACT-advanced-td:hover{
    background:rgb(80, 240, 255);
}
.SAT-advanced-answer-vertical{
    width: 25%;
    justify-content: center;
    text-align: center;
}
.SAT-advanced-answer-vertical table{
    margin: 0 auto;
    border-collapse: collapse;
    margin-top: 0.5rem;
}
.SAT-advanced-answer-vertical td{
    border: 1px black solid;
    width: 2.5rem;
    transition: 200ms ease;
}
.SAT-advanced-answer-vertical td.even{
    background: rgb(255, 243, 234);
}
.SAT-advanced-answer-vertical td:hover{
    background: rgb(80, 240, 255);
}
.custom-tool-tip{
    background: white;
    border: 1px grey solid;
    box-shadow: 1px 1px 1px grey;
    border-radius: 3px;
    padding: 0.5rem;
    text-align: left;
    transition: 200ms ease;
}
.SAT-Nivoline{
    max-width: 90rem;
    height: 40rem;
    border: 1px black solid;
    border-radius: 3px;
    margin: 1rem;
    border: 1px rgb(238, 223, 223) solid;
    box-shadow: 1px 1px 2px grey;
}
.q-breakdown-table{
    border-collapse: collapse;
}
.q-breakdown-td{
    padding: 0 0.5rem 0 0.5rem;
}
.q-breakdown-table tr{
    transition: 200ms ease;
}
.q-breakdown-table tr:hover{
    background: rgb(255, 205, 187);
}
@media screen and (max-width: 1400px){
    .SAT-advanced-td{
        font-size: 0.75rem;
        min-width: 1rem;
    }
    .SAT-Nivoline{
        height: 35rem;
    }
}
@media screen and (max-width: 400px){
    .SAT-advanced-answer-vertical td{
        width: 1.5rem;
    }
    .q-breakdown-table{
        font-size: 0.75rem;
    }
    .q-breakdown-td{
        padding: 0 0.1rem 0 0.1rem;
    }
}