.std-container{
    align-items: center;
    justify-content: center;
    padding: 1rem;
}
.std-subsection-container{
    display: flex;
    flex-direction: row;
    max-width: 80rem;
    margin: 0 auto;
    padding: 2rem 0 3rem 0;
}
.std-subsection-title-div{
    width: 50%;
    font-size: 2rem;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.5rem;
}
.std-icon{
    width: 2.75rem;
    aspect-ratio: 1;
    vertical-align: middle;
    margin: 0 5px 0 5px;
}
.std-subsection-description-div{
    width: 50%;
    text-align: left;
    padding: 1.5rem 2rem 1.5rem 2rem;
    background: #e6e2d6;
    border-radius: 1rem;
    box-shadow: 2px 2px 2px rgb(194, 194, 194);
}
@media screen and (max-width: 550px){
    .std-subsection-container{
        flex-direction: column;
    }
    .std-subsection-title-div{
        width: 100%;
    }
    .std-subsection-description-div{
        width: 100%;
    }
}
@media screen and (min-width: 1000px){
    .std-subsection-title-div{
        font-size: 3rem;
    }
    .std-icon{
        width: 3.5rem;
    }
}

.std-divider{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 1rem 0;
    margin: 0.5rem auto;
}

.std-divider::before,
.std-divider::after{
    content:"";
    display: block;
    height: 1px;
    flex-grow: 1;
    background: black;
}

.orange-std-divider-circle{
    width: 0.75rem;
    aspect-ratio: 1;
    background: orange;
    border-radius: 50%;
}

.teal-std-divider-circle{
    width: 0.75rem;
    aspect-ratio: 1;
    background: teal;
    border-radius: 50%;
}