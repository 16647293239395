.contacts-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
    gap: 2rem;
    justify-content: center;
    padding: 0 1rem 2rem 1rem;
}
  
.contacts-container{
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #333;
    color: white;
    opacity: 0;
    transition: opacity 1.25s;
}

.contacts-container.visible{
    opacity: 1;
}

.back-to-top-button{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}
.back-to-top-icon{
    width: 3rem;
    aspect-ratio: 1;
}

.contacts-button{
    background: lightblue;
    transition: 300ms ease;
    color: black;
    border: 2px rgb(0, 0, 78) solid;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-weight: bold;
}

.contacts-button:hover{
    background: rgb(57, 177, 217);
}