.flippable-card-container{
    opacity: 0;
    filter: blur(5px);
    transform: translateY(30px);
    transition: 1s;
}
.flippable-card{
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(196, 224, 206);
    box-shadow: 0px 2px 5px gray;
    transition: 200ms;
    transform-style: preserve-3d;
    transform: perspective(1000px) rotateY(var(--rotate-y,0)) translateY(var(--translate-y,0));
}
.flippable-card:hover{
    --translate-y: -2px;
    box-shadow: 0px 2px 5px rgb(66, 66, 66);
}
.flippable-card:hover button{
    background-color: #333;
    color: white;
    cursor: pointer;
}
.flippable-card.flipped{
    --rotate-y: 180deg;
}
.flippable-card .front p, ul{
    text-align: left;
}
.flippable-card .front, .flippable-card .back{
    top: 0;
    position: absolute;
    backface-visibility: hidden;
    align-content: center;
    transform: rotateX(0deg);
    background: rgb(196, 224, 206);
    padding: 1rem;
}
.flippable-card .back{
    transform: rotateY(180deg);
}
.flippable-card .back p, ul{
    text-align: left;
}
.flippable-card-button{
    padding: 0.5rem;
    font-weight: bold;
    margin: 1rem;
    border: 2px solid #333;
    border-radius: 2px;
    background: transparent;
    transition: 200ms ease-in;
}
.flippable-card-container.visible{
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}