.overview-platform-links-container{
    display: flex;
    flex-direction: row;
}
.overview-platform-links{
    border: 2px black solid;
    background: #fffdf7;
    border-radius: 1rem;
    text-align: left;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 1rem;
    transition: 300ms ease;
}
.overview-platform-links:hover{
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
}
.overview-platform-links-title{
    font-size: 2.25rem;
}
.overview-platform-links-description{
    font-size: 1.5rem;
}
.dashboard-score-display-container{
    border: 2px black solid;
    background: #fffdf7;
    border-radius: 1rem;
    width: fit-content;
    margin: 1rem;
    padding: 1rem;
    transition: 300ms ease;
}
.dashboard-score-display-container:hover{
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
}
.overview-button{
    border: 1px black solid;
    border-radius: 5px;
    padding: 0.25rem 1rem 0.25rem 1rem;
    margin-top: 0.25rem;
    font-size: 1rem;
    background: lightblue;
    color: black;
    transition: 200ms ease;
    cursor: pointer;
}
.overview-button:hover{
    background: black;
    color: white;
}

@media screen and (max-width: 500px){
    .overview-platform-links-container{
        flex-direction: column;
    }
    .overview-platform-links-title{
        font-size: 1.5rem;
    }
    .overview-platform-links-description{
        font-size: 1rem;
    }
}