.courses-container{
    width: 100%;
}

.courses-title-container{
    display: flex;
    width: 100%;
    background: black;
    color: white;
    align-items: center;
    justify-content: space-between;
}
.courses-icon{
    width: 3rem;
    aspect-ratio: 1;
}
.courses-title{
    display: flex;
}
.courses-title h1{
    padding: 0.5rem;
    margin: 0;
}

.courses-content{
    /* background: linear-gradient(#e4c1f9,rgb(230, 230, 230)); */
    background: #f8f4e7;
    padding-bottom: 1rem;
}
.courses-nav{
    display: flex;
}
.courses-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 30rem));
    align-items: center;
    justify-content: center;
}

.course{
    position: relative;
    /* background: linear-gradient(rgb(230, 230, 230),#e4c1f9); */
    background: #e4e0d4;
    box-shadow: 0px 2px 5px gray;
    opacity: 0;
    transform: translateY(20px);
    filter: blur(2px);
    margin: 1rem;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
}
.course.visible{
    opacity: 1;
    transform: translateY(0);
    filter: none;
    transition: transform 1s ease-in, opacity 1.5s, filter 1.5s;
}
.course-icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    aspect-ratio: 1;
}
.course-title h2{
    margin: 0.5rem 0 0.5rem 0;
    padding: 0;
}
.course-description{
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem 0rem 1.5rem;
    text-align: left;
}
.course-description span{
    margin-bottom: 0.5rem;
}