.dashboard-links-div{
    padding: 0.25rem;
    font-weight: bold;
}
.dashboard-links-div button{
    color: black;
    font-weight: bold;
    background: orange;
    border: 1px black solid;
    border-radius: 0.5rem;
    transition: 300ms ease;
    padding: 0.35rem;
}
.dashboard-links-div button:hover{
    color:white;
    background: black;
}
.student-homework-table{
    margin-top: 2rem;
}
.student-homework-table td{
    padding: 0.25rem 1rem 0 0;
}
.student-homework-icon{
    width: 1rem;
    aspect-ratio: 1;
}
.dashboard{
    padding: 0 1rem 0 1rem;
}
.dashboard-scaled-score-table{
    text-align: left;
}
.dashboard-scaled-score{
    font-size: 2.5rem;
    font-weight: bold;
    color: navy;
}
.dashboard-scaled-score-title{
    font-weight: bold;
    font-size: 1.5rem;
    /* color: navy; */
}
.dashboard-scaled-score-total{
    font-size: 1.2rem;
}
@media screen and (max-width: 480px){
    .student-homework-table{
        font-size: 0.8rem;
    }
}