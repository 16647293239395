.big-dashboard-container{
    display: flex;
    flex-direction: row;
    background: rgb(241, 241, 255);
}
.dashboard-sidebar{
    min-height: 100vh;
    width: 15rem;
    background: rgb(0, 0, 51);
    color: white;
}
.dashboard-sidebar-info{
    margin-top: 1.5rem;
    font-weight: bold;
    font-size: 1.5rem;
}
.dashboard-sidebar-nav{
    margin-top: 1rem;
    text-align: left;
    padding: 0.5rem;
    font-size: 1.25rem;
    border-right: 1px black solid;
}
.dashboard-sidebar-nav-item{
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 0.25rem 0 0.25rem 0;
    border-radius: 0.5rem;
    transition: 300ms ease;
    cursor: pointer;
}
.dashboard-sidebar-nav-item:hover{
    background: lightblue;
    color: black;
}
.dashboard-sidebar-title{
    margin-top: 1.5rem;
    font-size: 1rem;
    text-decoration: underline;
}
.dashboard-bottombar{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    box-shadow: 0 -1px 1px grey;
    z-index: 10;
    display: flex;
    justify-content: space-around;
}
.dashboard-bottombar-button{
    position: relative;
    background: white;
    border-radius: 0.5rem;
    padding: 0.25rem 0 0.25rem 0;
    width: 23%;
    transition: 200ms ease;
}
.dashboard-bottombar-button:hover{
    background: rgb(255, 243, 220);
}
.dashboard-submenu{
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    z-index: 11;
    background: white;
    border: 1px black solid;
    width: max(10rem,100%);
    text-align: left;
}
.dashboard-submenu div{
    padding: 0.25rem 1rem 0.25rem 1rem;
    transition: 200ms ease;
}
.dashboard-submenu div:hover{
    background: rgb(255, 243, 220);
}
.big-dashboard-content-container{
    width: calc(100% - 15rem);
    min-height: 100vh;
}
@media screen and (max-width: 950px){
    .big-dashboard-content-container{
        width: 100%;
        padding-bottom: 4rem;
    }
}