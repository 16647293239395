.marquee-container{
    overflow: hidden;
    padding: 1rem;
    font-weight: bold;
    mask-image: linear-gradient(to right, transparent, white 10%, white 90%, transparent);
}

.marquee-components-container{
    width: max-content;
    display: flex;
    flex-direction: row;
    animation: marquee-scroll 20s infinite linear;
}

.marquee-components-container.paused{
    animation-play-state: paused;
}

.marquee-components-container div{
    display: flex;
    align-items: center;
    margin-left: 2rem;
}

.marquee-components-container img{
    width: 1.75rem;
    aspect-ratio: 1;
}

@keyframes marquee-scroll{
    0%{
        transform: translateX(0%);
    }100%{
        transform: translateX(-50%);
    }
}
