.faq-container{
    max-width: 800px;
    margin: 0px auto;
    padding: 0 0.5rem 5rem 0.5rem;
}
.faq{
    border-bottom: 3px solid black;
}
.faq-q{
    padding: 0.5rem;
    text-align: left;
    font-weight: bold;
    font-size: 1.25rem;
    display: flex;
    justify-content: space-between;
    vertical-align: center;
    cursor: pointer;
    transition: 400ms ease;
}
.faq-q:hover{
    background: rgb(218, 240, 247);
}
.faq-arrow{
    width: 1.75rem;
    height: 1.75rem;
    transform: rotate(0deg);
    transition: 400ms ease;
}
.faq-arrow.expanded{
    transform: rotate(180deg);
    transition: 400ms ease;
}
.faq-a{
    padding: 0 0.5rem 0.5rem 0.5rem;
    text-align: left;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: 800ms ease;
}
.faq-a.expanded{
    opacity: 1;
    max-height: 2000px;
    transition: 800ms ease;
}
.faq ul{
    list-style: decimal;
}
.faq li{
    padding-bottom: 0.5rem;
}
.faq-img{
    width: 70%;
    border: 2px black solid;
    margin-top: 0.4rem;
}

@media screen and (max-width: 700px){
    .faq-img{
        width: 100%;
    }
}