.summer-title{
    position: relative;
    top: 1rem;
    padding: 2rem 0.5rem 2rem 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px red solid; */
}
.summer-title-text{
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    font-weight: bold;
}
.palm-trees{
    opacity: 50%;
}
.palm-trees-background{
    display: none;
    height: 100%;
    aspect-ratio: 1;
    opacity: 10%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
@media screen and (max-width:998px){
    .palm-trees-background{
        display: block;
    }
    .palm-trees{
        display: none;
    }
}
.popsicle-left{
    animation: animate 2s infinite;
    transform-origin: bottom;
}
.popsicle-right{
    animation: animate 2s infinite;
    transform-origin: bottom;
}
@keyframes animate{
    0%{
        transform: rotate(-20deg);
    }
    50%{
        transform: rotate(20deg);
    }
    100%{
        transform: rotate(-20deg);
    }
}
.summer-nav-container{
    margin: 1rem 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* border: 2px black solid; */
}
.summer-nav-box{
    display: flex;
    position: relative;
    background: #f8f4e7;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-bottom: 0.5rem;
    cursor: pointer;
}
.summer-spinner{
    content: '';
    position: absolute;
    width: 10%;
    aspect-ratio: 0.1;
    background: linear-gradient(orange, yellow);
    /* animation: spin 4s linear infinite; */
}
.summer-nav-box:hover .summer-spinner{
    filter: blur(1px);
    animation: spin 4s linear infinite;
}
.summer-nav-box::after{
    content: '';
    position: absolute;
    inset: 4px;
    background: #f8f4e7;
    border-radius: 10px;
}
@keyframes spin{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.summer-nav-text{
    display: flex;
    flex-wrap: wrap;
}
.summer-nav-text span{
    display: flex;
    position: relative;
    margin: 0.5rem 1rem 0.5rem 1rem;
    font-size: 1.5rem;
    text-decoration: underline;
    z-index: 10;
    user-select: none;
}
.summer-nav-icon{
    height: 2rem;
    aspect-ratio: 1;
}
.summer-date{
    font-size: 1.5rem;
    font-weight: bold;
}

.summer-content-container{
    padding-bottom: 1rem;
}
.summer-content-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    background: black;
}
.summer-content-title-text{
    display: flex;
    align-items: center;
    justify-content: center;
}
.summer-content-title-text h2{
    margin: 0;
    padding: 0.5rem 0 0.5rem 0;
}
.summer-content-title-icon{
    width: 2rem;
    aspect-ratio: 1;
}
.nav-up, .nav-down{
    display: flex;
    align-items: center;
    padding-left: 1rem;
    cursor: pointer;
}
.nav-up-arrow{
    width: 2rem;
    aspect-ratio: 1;
    /* border: 2px red solid; */
}
.nav-down-arrow{
    width: 2rem;
    aspect-ratio: 1;
    rotate: 180deg;
}
@media screen and (max-width: 992px){
    .nav-up span{
        display: none;
    }
    .nav-down span{
        display: none;
    }
}
.summer-content{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    /* border: 2px green solid; */
}
.sat-content{
    position: relative;
    width: min(100rem,100%);
    /* border: 2px red solid; */
}
.sat-icon{
    width: 5rem;
    aspect-ratio: 1;
}
.sat-steps{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 15rem);
    gap: 1rem;
    justify-content: center;
    /* border: 2px blue solid; */
}
.sat-steps h3{
    margin: 0;
    padding: 0;
}
.sat-step{
    margin-bottom: 1rem;
    /* border: 3px rgb(44, 44, 44) solid; */
    /* border-radius: 1rem; */
    /* box-shadow: 0px 2px 5px grey; */
}
.sat-step ul{
    margin: 0.5rem 0 0 0;
}
.summer-digital-sat-container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.digital-sat-descsription{
    /* position: relative; */
    display: flex;
    flex-direction: column;
    width: min(50rem,100%);
    /* border: 2px pink solid; */
}
.summer-course-container{
    position: relative;
    width: min(60rem, 100%);
    padding: 0.5rem;
    text-align: left;
    /* border: 2px black solid; */
}
.summer-course-container h2{
    margin: 0;
    text-decoration: underline;
}
.summer-sat-divider{
    position: relative;
    left: 20%;
    width: 60%;
    height: 2rem;
    border-bottom: 5px orange solid;
}
.summer-divider{
    position: relative;
    width: 100%;
    height: 2rem;
    border-bottom: 5px orange solid;
}
.summer-course-schedules{
    width: min(60rem,100%);
}
.summer-schedule{
    position: relative;
    /* width: min(60rem, 100%); */
    padding: 0.5rem;
    /* border: 1px red solid; */
}
.summer-schedule-wrapper{
    display: flex;
    /* border: 1px blue solid; */
    width: 100%;
}
.summer-schedule-time-table{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 60%;
    padding: 1rem;
    text-align: left;
    /* border: 1px green solid; */
}
.summer-schedule-time-table h2{
    margin: 0;
    text-decoration: underline;
}
.summer-schedule-time-table h3{
    margin: 1.5rem 0 0.5rem 0;
}
.summer-schedule-time-table span{
    display: block;
}
.summer-schedule-time-table table{
    display: block;
    margin-bottom: 0.5rem;
}
.summer-button-container{
    display: flex;
}
.summer-button{
    margin: 0.5rem 1rem 0.5rem 0;
    padding: 0.5rem;
    background: orange;
    border: none;
    box-shadow: 0px 2px 5px grey;
    transition: 0.5s;
}
.summer-button:hover{
    background: yellow;
    transition: 0.5s;
}
.summer-schedule-price{
    width: 40%;
    /* border: 1px pink solid; */
    padding: 1rem;
    text-align: left;
}
.summer-schedule-price h2{
    margin: 0;
    text-decoration: underline;
}
.summer-schedule-price h4{
    margin-bottom: 0;
}
.summer-schedule-price ul{
    margin: 0;
}

@media screen and (max-width: 700px){
    .summer-schedule-wrapper{
        flex-direction: column;
    }
    .summer-schedule-time-table{
        width: 100%;
    }
    .summer-schedule-price{
        Width: 100%;
    }
    .subjects-grid-container-div{
        font-size: 1.1rem;
    }
}

.calendar-wrapper{
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 490px){
    .calendar-wrapper{
        flex-direction: column;
    }
}

.subjects-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 20px;
    max-width: 60rem;
    margin: auto;
    padding: 0 0.5rem 0 0.5rem;
    font-size: 1.2rem;
}

.subjects-grid-container-div {
    display: flex;
    align-items: center;
    font-weight: bold;
    /* font-size: 1.2rem; */
    text-align: center;
    white-space: nowrap;
    transition: color 0.3s;
    margin-bottom: 0.5rem;
}

.subjects-grid-container img{
    width: 1.5rem;
    aspect-ratio: 1;
}

.subjects-grid-container div:hover {
    color: blue;
    transition: color 0.3s;
}
