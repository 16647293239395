.small-card{
    overflow: hidden;
    display: flex;
    background-color: rgb(196, 224, 206);
    box-shadow: 0px 2px 5px gray;
    border-radius: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    transform: translateY(30px);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    opacity: 0;
    filter: blur(5px);
  }
  .small-card-image{
    width: 100%;
    object-fit: cover;
  }
  .small-card-title{
    padding: 0.5rem;
  }
  .small-card-description{
    text-align: left;
    padding: 0 1rem;
  }
  .small-card button{
    padding: 0.5rem;
    font-weight: bold;
    margin: 1rem;
    border: 2px solid #333;
    border-radius: 2px;
    background: transparent;
    transition: 200ms ease-in;
  }
  @media screen and (min-width: 992px){
    .small-card.visible:hover{
      background-color:rgb(174, 233, 195);
      box-shadow: 0px 2px 5px rgb(66, 66, 66);
    }
  }
  .small-card button:hover{
    background-color: #333;
    color: white;
    cursor: pointer;
  }
  .small-card.visible{
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
    transition: transform 200ms ease-in, opacity 1s, transformY 1s, background-color 1s;
  }
  .small-card:nth-child(2){
    transition-delay: 200ms;
  }
  .small-card:nth-child(3){
    transition-delay: 400ms;
  }