.admin-sidebar{
    width: 15rem;
    background: navy;
    height: 100vh;
    box-sizing: border-box;
}
.admin-content{
    width: calc(100vw - 15rem);
    background: lightblue;
}
.admin-content-ex{
    width: 100%;
    height: 4rem;
    background: red;
}
.admin-content-ex2{
    width: 100%;
    height: 5rem;
    background: green;
}
.admin-table-container{
    width: 100%;
    overflow-x:auto;
}