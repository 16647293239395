.schedule-menu-bar{
    height: 2.5rem;
    border-radius: 1rem;
    width: auto;
    position: sticky;
    top: 3.5rem;
    left: 0;
    background: rgba(224, 224, 224, 0.76);
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    padding: 0 1rem 0 1rem;
    z-index: 10;
}
.schedule-menu-button{
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    margin-right: 1rem;
    border-radius: 1rem;
    text-align: center;
    align-items: center;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    transition: width 1s ease;
    cursor: pointer;
}
.schedule-menu-button.hover{
    width: 8.5rem;
}
.schedule-menu-button.hover-longer{
    width: 10rem;
}
.schedule-menu-button.hover-shorter{
    width: 8rem;
}
.toggle-calendar-button{
    background: orange;
    border: 1px solid black;
    border-radius: 0.25rem;
    font-size: 1.25rem;
    font-weight: bold;
    transition: 200ms ease;
    margin: 0.5rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    cursor: pointer;
}
.toggle-calendar-button:hover{
    background: rgb(0, 0, 0);
    color:white;
}
.book-lesson-button{
    border: 1px black solid;
    border-radius: 0.25rem;
    font-size: 1.5rem;
    font-weight: bold;
    transition: 200ms ease;
    margin: 0.5rem;
    padding: 0 1rem 0 1rem;
    cursor: pointer;
}
.book-lesson-button.blue-button{
    background: lightblue;
}
.book-lesson-button.blue-button:hover{
    background: #73cae7;
}
.book-lesson-button.green-button{
    background: #8aff8a;
}
.book-lesson-button.green-button:hover{
    background: #1cff1c;
}
.book-lesson-button.purple-button{
    background: #eaa4ff;
}
.book-lesson-button.purple-button:hover{
    background: #cf32ff;
}
.schedule-calendar-weekly-container{
    width: 95%;
    border: 1px black solid;
    margin: 0 auto;
}
.weekly-calendar-header{
    align-items: center;
    padding: 1rem;
}
.days-of-week-container{
    display: flex;
}
.days-of-week-container div{
    width: calc(100%/8);
}
.days-of-week-schedule-container{
    display: flex;
}
.days-of-week-column{
    position: relative;
    width: calc(100%/8);
    height: 56rem;
}
.weekly-calendar-lesson{
    position: absolute;
    left: 2%;
    width: 97%;
    background: rgba(255, 184, 117, 0.6);
    border-radius: 0.25rem;
    overflow: hidden;
    cursor: pointer;
}
.verification-check{
    position: absolute;
    top: 0;
    right: 0;
    width: 1rem;
    height: 1rem;
}

.lesson-detail-popup-div{
    position: absolute;
    top: 5rem;
    left: 5rem;
    background: white;
    width: 25rem;
    height: auto;
    border-radius: 1rem;
    border: 1px solid gray;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    z-index: 20;
    touch-action: none;
}
.lesson-detail-popup-div::-webkit-scrollbar{
    display: none;
}
.lesson-detail-title{
    font-size: 2rem;
    font-weight: bold;
    margin: 0 auto;
}
.lesson-detail-container{
    position: relative;
    text-align: left;
    padding: 1rem;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 1rem;
    overflow-y: hidden;
    transition: height 1s;
}
.lesson-detail-container.expanded{
    transition: height 1s;
}
.lesson-detail{
    display: block;
    margin-top: 1rem;
}
.lesson-detail-bar{
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    width: 100%;
    cursor: pointer;
}
.lesson-detail-student{
    display: block;
    margin-top: 0.25rem;
}
.lesson-detail-button{
    margin-top: 1rem;
    padding: 0.2rem 1rem 0.2rem 1rem;
}
.student-search-dropdown{
    border: 1px black solid;
    height: 5rem;
    overflow-y: scroll;
    overflow-x: hidden;
}
.search-dropdown-container{
    position: relative;
    margin-right: 1rem;
}
.search-dropdown{
    position: absolute;
    background: white;
    border: 1px solid black;
    width: 100%;
    height: 15rem;
    z-index: 5;
    overflow-x: hidden;
    overflow-y: auto;
}
.search-results{
    padding: 0.2rem;
    text-align: left;
    transition: 300ms;
    text-wrap: nowrap;
}
.search-results:hover{
    background: lightblue;
}

.schedule-calendar-monthly-container{
    display: flex;
    flex-direction: row;
    padding: 0 2rem 0 2rem;
    justify-content: center;
}
.scheduler-calendar-container{
    width: 40rem;
}
.scheduler-calendar-container td{
    width: calc(40rem/7);
}
.scheduler-calendar-header{
    display: flex;
    justify-content: space-between;
}
.scheduler-calendar-date{
    position: relative;
    height: 3.5rem;
}
.calendar-blue-circle{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    background: rgba(0, 72, 255, 0.5);
    cursor: pointer;
}
.calendar-lesson-count{
    position: absolute;
    top:-0.25rem;
    right:-0.25rem;
    width:1rem;
    height:1rem;
    border-radius:50%;
    background: rgba(255, 17, 17, 0.9);
    font-size:0.75rem;
    align-items: center;
    justify-content: center;
}
.daily-schedule-container{
    border: 2px rgb(116, 116, 116) solid;
    border-radius: 5px;
    width: 35rem;
    margin: 1rem;
}