.about-us-container{
    width: 100%;
    /* background: #efe9ae; */
}

.about-us-content{
    /* background: linear-gradient(#efe9ae,rgb(230, 230, 230)); */
    background: #f8f4e7;
    padding: 1rem;
}

.about-us-title-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    background: black;
    /* padding: 1rem; */
}
.about-us-title{
    display: flex;
}
.about-us-title h1{
    margin: 0;
    padding: 0.5rem;
}

.about-us-icon{
    width: 3rem;
    aspect-ratio: 1;
}

.nav-up, .nav-down{
    display: flex;
    align-items: center;
    padding-left: 1rem;
    cursor: pointer;
}
.nav-up-arrow{
    width: 2rem;
    aspect-ratio: 1;
    /* border: 2px red solid; */
}
.nav-down-arrow{
    width: 2rem;
    aspect-ratio: 1;
    rotate: 180deg;
}
@media screen and (max-width: 992px){
    .nav-up span{
        display: none;
    }
    .nav-down span{
        display: none;
    }
}