.login-div{
    width: min(40rem,90%);
    /* height: max(30rem, 80vh); */
    height: 32rem;
    background: white;
    border-radius: 2rem;
    margin: 2rem auto;
    /* margin-top: 3rem; */
    overflow: hidden;
    display: flex;
    flex-direction: row;
}
.login-left{
    width: 50%;
    justify-content: center;
    align-items: center;
    /* border: 2px red solid; */
    display: flex;
}
.login-img-container{
    position: relative;
}
.isometric-diorama-img{
    width: 100%;
    aspect-ratio: 1;
}
.login-image-top-fade{
    position: absolute;
    background: linear-gradient(white, transparent);
    width: 100%;
    height: 2rem;
}
.login-image-bottom-fade{
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(transparent, white);
    width: 100%;
    height: 2rem;
}
.login-greeting{
    font-size: 1.5rem;
    font-weight: bold;
}
.login-form{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 1rem;
}
.login-form form{
    margin: 1rem 0 1rem 0;
}
.login-btn{
    padding: 0.25rem 2rem 0.25rem 2rem;
    margin: 1rem 0 1rem 0;
    color: white;
    background: black;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: 400ms ease;
}
.login-btn:hover{
    background: rgb(77, 77, 77);
}
.login-clickable{
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 600px){
    .login-form{
        width: 100%;
    }
    .login-left{
        display: none;
    }
    .login-div{
        width: min(20rem,90%);
    }
}