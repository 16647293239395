.vocab-greeting-container{
    margin-bottom: 1rem;
}

.vocab-greeting-container span{
    font-weight: bold;
    font-size: 1.5rem;
}

.vocab-instructions-container{
    color: darkred;
}

.word-list-container{
    display: inline-block;
    width: min(80rem, 95%);
}

.word-list{
    list-style: decimal;
    display: flex;
    flex-direction: column;
}

.word-span{
    font-weight: bold;
    font-size: 1.25rem;
}

.definition-span, .example-span{
    display: block;
    text-indent: 10px;
    margin: 0.25rem 0 0.25rem 0;
}

.student-submission-container{
    display: inline-block;
    width: min(80rem, 90%);
    padding: 1rem;
}

.AI-response-div{
    display: inline-block;
    width: min(80rem, 90%);
    height: 30rem;
    white-space: pre-line;
    text-align: left;
    padding: 1rem;
    color: rgb(0, 0, 155);
}

.vocab-button, .vocab-submit-button{
    border: 2px black solid;
    font-weight: bold;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 0 0.5rem 0 0.5rem;
    background: transparent;
    color: black;
    border-radius: 1rem;
    transition: 0.5s ease;
}

.vocab-button:hover{
    background: black;
    color: white;
}

.vocab-submit-button:hover{
    background: orange;
    font-size: 1.5rem;
}

:root {
    --bg-color: beige;
    --barsize: 20px;
}
  
.hr {
    width: 80%;
    height: 2px;
    display: block;
    position: relative;
    margin: 0 auto;
    padding: 1rem 0;
}

.hr::before,
.hr::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 50%;
    left: 0;
}

.hr::before {
    background: linear-gradient(90deg, var(--bg-color) 0%, var(--bg-color) 50%, transparent 50%, transparent 100%);
    background-size: var(--barsize);
    background-position: center;
    z-index: 1;
}

.hr::after {
    transition: opacity 0.3s ease, animation 0.3s ease;
    background: linear-gradient(
        to right, 
        #62efab 5%, 
        #F2EA7D 15%, 
        #F2EA7D 25%, 
        #FF8797 35%, 
        #FF8797 45%, 
        #e1a4f4 55%, 
        #e1a4f4 65%, 
        #82fff4 75%, 
        #82fff4 85%, 
        #62efab 95%
    );
    background-size: 200%;
    background-position: 0%;
    animation: bar 15s linear infinite;
}

@keyframes bar {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 200%;
    }
}

.hr.anim::before {
    background: linear-gradient(
        90deg,
        var(--bg-color) 0%, var(--bg-color) 5%,
        transparent 5%, transparent 10%,
        var(--bg-color) 10%, var(--bg-color) 15%,
        transparent 15%, transparent 20%,
        var(--bg-color) 20%, var(--bg-color) 25%,
        transparent 25%, transparent 30%,
        var(--bg-color) 30%, var(--bg-color) 35%,
        transparent 35%, transparent 40%,
        var(--bg-color) 40%, var(--bg-color) 45%,
        transparent 45%, transparent 50%,
        var(--bg-color) 50%, var(--bg-color) 55%,
        transparent 55%, transparent 60%,
        var(--bg-color) 60%, var(--bg-color) 65%,
        transparent 65%, transparent 70%,
        var(--bg-color) 70%, var(--bg-color) 75%,
        transparent 75%, transparent 80%,
        var(--bg-color) 80%, var(--bg-color) 85%,
        transparent 85%, transparent 90%,
        var(--bg-color) 90%, var(--bg-color) 95%,
        transparent 95%, transparent 100%
    );
    background-size: calc(var(--barsize) * 10);
    background-position: center;
    z-index: 1;
    animation: bar 120s linear infinite;
}

.hr.anim:hover::before {
    animation-duration: 20s;
}

.hr.anim:hover::after {
    animation-duration: 2s;
}
