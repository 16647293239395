.digital-sat-introduction-container{
    width: 100%;
}
.digital-sat-intro-title-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: black;
    color: white;
}
.digital-sat-intro-title{
    display: flex;
}
.digital-sat-intro-title h1{
    margin: 0.5rem 0 0.5rem 0;
}
.computer-icon{
    width: 3rem;
    aspect-ratio: 1;
}
.digital-sat-intro-content{
    width: 100%;
    padding: 1rem;
    background: linear-gradient(#ff928b,rgb(230, 230, 230));
}