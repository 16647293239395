.calendar{
    width: 20rem;
    display: block;
    border: 2px black solid;
}
.calendar-header{
    display: flex;
    justify-content: space-between;
    transition: 0.5s;
    font-weight: bold;
}
.calendar-days{
    width: 100%;
    table-layout: fixed;
}
.calendar-date{
    position: relative;
    height: 1.5rem;
}
.calendar-date-completed{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    height: 95%;
    aspect-ratio: 1;
    border-radius: 50%;
    background: green;
    opacity: 40%;
    cursor: pointer;
}
.calendar-date-assigned{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    height: 95%;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 3px solid blue;
    opacity: 50%;
    cursor: pointer;
}