.DT-big-title{
    display: block;
    padding: 2rem 1rem 2rem 1rem;
    font-size: 4rem;
    font-weight: bold;
}
.DT-introduction-container{
    font-size: 1.25rem;
    display: flex;
    max-width: 90rem;
    margin: 0 auto;
    margin-bottom: 2rem;
}
.DT-introduction-text{
    width: 40%;
    text-align: left;
    padding: 1rem 2rem 1rem 2rem;
}
.DT-introduction-animation{
    position: relative;
    width: 60%;
    height: 10rem;
    overflow: hidden;
    padding: 0 1rem 0 1rem;
}
.DT-sample-container{
    display: flex;
    font-size: 0.8rem;
    height: 10rem;
    background: #fffef9;
}
.DT-sample-question{
    width: 36%;
    border: 1px black solid;
    text-align: left;
    padding: 0.5rem;
}
.DT-sample-q{
    width: 100%;
    height: 5rem;
    border-radius: 1rem;
    background: rgb(180, 180, 180);
}
.DT-sample-mc{
    width: 32%;
    border: 1px black solid;
    padding: 0.5rem;
}
.DT-sample-mc button{
    font-size: 0.6rem;
    background: transparent;
    border: 1px black solid;
    border-radius: 0.5rem;
    margin: 0.5rem 0.25rem 0 0.25rem;
    transition: 700ms ease;
}
.DT-sample-mc button:hover{
    background: black;
    color: white;
}
.DT-sample-question-grid{
    width: 32%;
    border: 1px black solid;
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
}
.DT-sample-question-grid div{
    width: 2.5rem;
    text-align: left;
}
.DT-faded-edge{
    width: 100%;
    height: 3rem;
    position: absolute;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 0%, #f8f4e7 100%);
}
.DT-title{
    font-size: 3rem;
    font-weight: bold;
}
.DT-title-container{
    padding: 0 2rem 0 2rem;
}
.DT-introduction-subsection-container{
    max-width: 50rem;
    text-align: left;
    padding: 1rem;
}
.DT-intro-card-container{
    justify-content: center;
    align-items: center;
    display: flex;
}
.DT-intro-card-grid{
    padding: 1rem;
    max-width: 70rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}
.DT-intro-card{
    width: 20rem;
    background: #e6e2d6;
    padding: 1rem;
    border-radius: 1rem;
}
.DT-example-image{
    width: min(80%,70rem);
    border: 2px black solid;
}
.DT-intro-animation-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
.DT-intro-computer-img{
    width: 6rem;
    aspect-ratio: 1;
}
.animated-dashed-lines{
    width: 6rem;
    aspect-ratio: 1;
}
.DT-timer{
    font-weight: bold;
    color: red;
    position: absolute;
    top: 2rem;
}
.TD-intro-question{
    width: 50%;
    height: 6rem;
    text-align: left;
    overflow-y: hidden;
}
.TD-intro-question-grid{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 1rem;
}
.TD-intro-question-grid div{
    width: 2.5rem;
    height: 1.5rem;
    text-align: left;
    font-size: 0.75rem;
}
.TD-question-number{
    font-weight: bold;
    font-size: 0.75rem;
}
.TD-question-description{
    font-size: 0.35rem;
}
.TD-people-container{
    height: 6rem;
    display: flex;
    gap: 1rem;
}
.TD-person-container{
    width: 4rem;
    aspect-ratio: 1;
    position: relative;
}
.TD-person-icon{
    width: 100%;
    aspect-ratio: 1;
}
.TD-green-dot{
    position: absolute;
    top: 0;
    right: 0;
    width: 1rem;
    aspect-ratio: 1;
    background: rgb(0, 221, 0);
    border-radius: 50%;
}
.TD-red-dot{
    position: absolute;
    top: 0;
    right: 0;
    width: 1rem;
    aspect-ratio: 1;
    background: rgb(0, 221, 0);
    border-radius: 50%;
    animation: colorChange 5s infinite;
} 
@keyframes colorChange{
    0%{
        background: rgb(0,221,0);
    } 41%{
        background: rgb(0,221,0);
    } 51%{
        background: rgb(221,0,0);
    } 90%{
        background: rgb(221,0,0);
    } 100%{
        background: rgb(0,221,0);
    }
}
.TD-question-image{
    width: 18rem;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 600px){
    .DT-big-title{
        font-size: 2rem;
    }
    .DT-title{
        font-size: 2rem;
    }
}

@media screen and (max-width: 1050px){
    .DT-introduction-container{
        flex-direction:column;
    }
    .DT-introduction-text{
        width: 100%;
    }
    .DT-introduction-animation{
        width: 100%;
    }
}
@media screen and (max-width: 480px){
    /* .DT-introduction-animation{
        display: none;
    } */
    .DT-sample-question{
        width: 52%;
    }
    .DT-sample-mc{
        width: 48%;
    }
    .DT-sample-question-grid{
        display: none;
    }
}

.contact-arrow-button{
    display: flex;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 1rem 0 0 1rem;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background: rgb(249, 190, 190);
    border: 1px black solid;
    border-radius: 1rem;
    cursor: pointer;
    transition: 700ms ease;
}
.contact-arrow-button:hover{
    background: black;
    color: white;
}
.contact-arrow-button span{
    vertical-align: middle;
}
.contact-arrow-img-container{
    position: relative;
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    margin-left: 0.25rem;
}
.contact-arrow-black{
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 1;
}
.contact-arrow-black.invisible{
    opacity: 0;
}
.contact-arrow-white{
    width: 1rem;
    height: 1rem;
    z-index: 2;
    display: none;
}
.contact-arrow-white.visible{
    display: block;
}
.first-arrow{
    animation: fadeIn1 4s infinite;
}
@keyframes fadeIn1{
    0%{
        opacity: 0;
    } 10%{
        opacity: 1;
    }
}
.second-arrow{
    animation: fadeIn2 4s infinite;
}
@keyframes fadeIn2{
    0%{
        opacity: 0;
    } 40%{
        opacity: 1;
    }
}
.third-arrow{
    animation: fadeIn3 4s infinite;
}
@keyframes fadeIn3{
    0%{
        opacity: 0;
    } 70%{
        opacity: 1;
    }
}