.full-screen-container{
  justify-content: center;
  align-items: center;
}
  
.TPA-title-container{
  margin: 2rem;
}
  
.TPA-title{
  font-size: 4rem;
  font-weight: bold;
}

.title-section-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.title-section-1{
  display: inline-block;
  width: min(50%, 60rem);
  /* border: 2px red solid; */
}
.title-section-2{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: min(50%, 60rem);
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  /* border: 2px green solid; */
  text-align: left;
}

.changing-images-container{
  width: 100%;
  aspect-ratio: 1.5;
  position: relative;
  overflow: hidden;
}

.changing-image{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  transition: opacity 400ms cubic-bezier(.55,.085,.68,.53);
  opacity: 0;
  transition-delay: 200ms;
}

.changing-image.is-visible, .changing-subtitle.is-visible{
  opacity: 1;
  transition-delay: 0ms;
}

.changing-titles-container{
  width: auto;
  position: relative;
  height: 4rem;
}

.changing-subtitle{
  width: max-content;
  position: absolute;
  left: 50%;
  text-align: center;
  transition: opacity 400ms cubic-bezier(.55,.085,.68,.53);
  opacity: 0;
  transition-delay: 50ms;
  font-size: 2rem;
  font-weight: bold;
  animation: float 8s infinite;
}

.latest-news-title{
  font-size: 2rem;
  font-weight: bold;
}

.latest-news{
  font-size: 1.25rem;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

.latest-news::before{
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: teal;
  transition: width 0.3s ease;
}

.latest-news:hover::before{
  width: 100%;
}

@media screen and (max-width: 768px){
  .title-section-container{
    flex-direction: column;
  }
  .title-section-1, .title-section-2{
    width: 100%;
  }
  .latest-news{
    font-size: 1rem;
  }
  .latest-news-title{
    font-size: 1.25rem;
  }
  .changing-subtitle{
    font-size: 1.5rem;
  }
  .changing-titles-container{
    height: 2.5rem;
  }
}

@media screen and (max-width: 450px){
  .TPA-title{
    font-size: 2.7rem;
  }
}
@media screen and (min-width: 950px){
  .TPA-title{
    font-size: 5rem;
  }
}

@keyframes float {
  0%, 100% {
    transform: translate(-50%, 0) rotate(1deg);
  }
  25% {
    transform: translate(-50%, -0.05rem) rotate(0deg);
  }
  50% {
    transform: translate(-50%, 0) rotate(-1deg);
  }
  75% {
    transform: translateY(-50%, -0.05rem) rotate(0deg);
  }
}