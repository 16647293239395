.nav {
    background-color: black;
    width: 100%;
    height: 2.35rem;
    top: 0px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
    position: fixed;
    z-index: 50;
    border-bottom: 1px grey solid;
}

.site-title {
  font-size: 1.5rem;
}

.home-icon {
  width: 1.5rem;
}

/* hamburger button */
.nav-icon2 {
  width: 2rem;
  height: .5rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.nav-icon2 span {
  display: block;
  position: absolute;
  height: .25rem;
  width: 50%;
  background: lightblue;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

.nav-icon2 span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

.nav-icon2 span:nth-child(1), .nav-icon2 span:nth-child(2) {
  top: 0.4rem;
}

.nav-icon2 span:nth-child(3), .nav-icon2 span:nth-child(4) {
  top: 1rem;
}

.nav-icon2 span:nth-child(5), .nav-icon2 span:nth-child(6) {
  top: 1.6rem;
}

.nav-icon2.open span:nth-child(1),.nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-icon2.open span:nth-child(2),.nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.nav-icon2.open span:nth-child(1) {
  left: 0.2rem;
  top: 0.65rem;
}

.nav-icon2.open span:nth-child(2) {
  left: calc(50% - 0.2rem);
  top: 0.65rem;
}

.nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.nav-icon2.open span:nth-child(5) {
  left: 0.2rem;
  top: 1.25rem;
}

.nav-icon2.open span:nth-child(6) {
  left: calc(50% - 0.2rem);
  top: 1.25rem;
}

.nav-ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: #555;
  transition: all 200ms ease-in-out;
}

.nav li:hover {
  background-color: #777;
  transition: all 200ms ease-in-out;
}

.sidebar {
  position: fixed;
  top: 2rem;
  background: #333;
  color: white;
  z-index: 50;
}

.sidebar ul{
  list-style: none;
  color: white;
  text-decoration: none;
  color: inherit;
  padding-left: 1rem;
  padding-right: 4rem;
}

.sidebar a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.sidebar li.active {
  background-color: #555;
  transition: all 200ms ease-in-out;
}

.sidebar li:hover {
  background-color: #777;
  transition: all 200ms ease-in-out;
}

.sidebard.closemenu {
  display: none;
}

.dropdown-menu{
  position: absolute;
  color: #333;
  cursor: pointer;
  background-color: #333;
  flex-direction: column;
  width: max-content;
  list-style: none;
  padding: 0;
}

.dropdown-menu a {
  padding: 1rem;
  text-decoration: none;
  color: white;
  transition: background-color 0.3s ease;
  background: #333;
}

.dropdown-menu a:hover {
  background-color: #777;
}