.choose-students-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem 0 1rem 0;
}
.select-students-container{
    padding: 1rem 0 1rem 0;
    width: min(30rem, 100%);
    height: max(80vh, 15rem);
    overflow: auto;
}
.bundle-container{
    width: min(20rem, 100%);
}