* {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  background-color: #f8f4e7;
  /* background-color: white; */
  padding-top: 2rem;
}

body::-webkit-scrollbar{
  width: 0.4rem;
}
body::-webkit-scrollbar-thumb{
  background: #333;
}

.home-logo{
  width: 50%;
}

.loginout-icon{
  width: 1rem;
}

.blinking{
  animation: blinking-animation 1s steps(1, end) infinite;
}

@keyframes blinking-animation{
  0%,100%{
      opacity: 1;
  }
  50%{
      opacity: 0;
  }
}

.left-justify{
  text-align: left;
  background-color: lightgrey;
}

.right-justify{
  text-align: right;
  background-color:lightgrey;
}

.dashboard{
  text-align: left;
}

.rowbox{
  display:flex;
  justify-content: center;
  flex-direction:row;
}

.rowtable{
  display: flex;
  justify-content: left;
  flex-direction: row;
}

.question-type-container{
  padding-right: 4rem;
}

.question-type-table td{
  padding-left: 0.25rem;
  padding-right: 0.5rem;
}

.question-type-table tr:hover{
  filter: brightness(90%) saturate(120%);
}

.rowbox-leftjustify{
  display:flex;
  justify-content: left;
  flex-direction:row;
}

.box{
  width: 45%;
  background-color: lightgrey;
  margin-bottom: 30px;
  margin-left: 30px;
  height: 80%;
  padding: 5px 10px 10px 10px;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 5px 5px 5px gray;
}

.test-box{
  width: 38%;
  background-color: lightgrey;
  margin-bottom: 30px;
  margin-left: 20px;
  margin-right: 20px;
  height: 80%;
  padding: 5px 10px 10px 10px;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 5px 5px 5px gray;
}

.home-box{
  width: 32%;
  background-color: lightgrey;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  height: 80%;
  padding: 5px 10px 10px 10px;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 5px 5px 5px gray;
}

.testing-center-box{
  width: 50%;
  text-align: center;
}

.chart-wrapper{
  margin: 1rem;
  padding: 0rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 0.5fr));
  justify-content: center;
}

ul.b{
  list-style-type:square;
}

.container {
  margin: 0;
  text-align: center;
}

.scroll-container {
  overflow-x: auto;
  height: auto;
}

.scroll-container::-webkit-scrollbar{
  height: 0.4rem;
}
.scroll-container::-webkit-scrollbar-thumb{
  background: #333;
}

.test-container {
  background-color: lightgray;
  height: 95vh;
}

.admin-forms {
  list-style: none;
}

.admin-list {
  margin: 10px;
}

.admin-input {
  width: 70%;
}

.student-list-container{
  overflow: auto;
  white-space: nowrap;
  height: 65vh;
  width: 50%;
  background-color: lightgray;
}

.test-list-container{
  overflow: auto;
  white-space: nowrap;
  height: 70vh;
  width: 15%;
  background-color: lightgray;
}

.SATACT-list-container{
  overflow: auto;
  white-space: nowrap;
  height: 65vh;
  width: 25%;
  background-color: lightgray;
}

.answer-key-container{
  overflow: auto;
  white-space: nowrap;
  height: 70vh;
  width: 85%;
  background-color: lightgray;
}

.answer-key-table{
  border-collapse: collapse;
  table-layout: fixed;
  /* display: flex;
  max-width: 90%; */
}

.answer-key-table-row td:nth-child(even){
  background-color: azure;
}

.answer-key-table-element{
  border: solid;
  text-align: center;
  min-width: 1.25rem;
}

.answer-key-table-body td:hover{
  background-color: coral;
}

.student-ul{
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
}

.loading {
  font-weight: bold;
  display:inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

.small-table-element{
  width: 3em
}

.title-content-wrapper{
  margin: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max(25rem,45%), 1fr));
  gap: 1rem;
}

.small-card-wrapper{
  margin: 1rem;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  gap: 2rem;
  justify-content: center;
}

.course-card-wrapper{
  margin: 1rem;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max(22rem,45%), 1fr));
  gap: 2rem;
  justify-content: center;
}

.flippable-card-wrapper{
  margin: 1rem;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max(22rem,45%), 1fr));
  gap: 2rem;
  justify-content: center;
}
.flippable-card-container:nth-child(2){
  transition-delay: 200ms;
}
.flippable-card-container:nth-child(3){
  transition-delay: 400ms;
}

.digital-testing-wrapper{
  margin: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  gap: 2rem;
}

.digital-testing-container img{
  width: 100%;
  pointer-events: none;
  user-select: none;
  border: 2px solid black;
}

.digital-testing-container button{
  font-weight: bold;
  border-radius: 0.5rem;
  border: 2px solid #333;
  padding: 0.25rem 1rem 0.25rem 1rem;
  background: transparent;
  transition: 150ms ease-in;
  margin: 1rem;
}

.digital-testing-container button:hover{
  color: white;
  background: black;
}

.student-answers-wrapper{
  margin: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
  gap: 2rem;
  justify-content: center;
  padding: 0 1rem 2rem 1rem;
}

.admin-wrapper{
  padding: 1rem;
  width: 100%;
  display: flex;
}
@media screen and (max-width: 700px){
  .side-container{
    display: none;
  }
}

.overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:rgba(27, 27, 27, 0.4);
  z-index: 10;
}

.overlay img{
  max-width: 70%;
  max-height: 90%;
  padding-top: 2rem; 
}

.timer{
  font-weight: bold;
  color: red;
  font-size: 1.25rem;
  padding-top: 0;
  padding-bottom: 0.5rem;
}

.calculator{
  width: 50%;
  height: 50%;
}

.title-box{
  position: relative;
}

.homework-button,
.homework-button:after {
  /* width: 150px; */
  /* height: 76px; */
  line-height: 78px;
  font-size: 20px;
  font-family: 'Bebas Neue', sans-serif;
  background: linear-gradient(45deg, transparent 5%, #FF013C 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.homework-button:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);
  
  content: 'HOMEWORK PAGE';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
  clip-path: var(--slice-0);
}

.homework-button:hover:after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }
  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }
  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }
  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}