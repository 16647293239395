.act-results-table{
    padding: 0 0 3rem 0;
}
.act-results-table td:first-child{
    min-width: 7rem;
}
.act-results-table td:nth-child(2){
    min-width: 7rem;
}
.act-answer-container-vertical{
    display: flex;
    justify-content: space-around;
}
.act-answer-container-vertical table{
    border-collapse: collapse;
    margin: 0.5rem 0 2rem 0;
}
.act-answer-container-vertical td{
    border: 1px black solid;
    min-width: 2rem;
    text-align: center;
    transition: 200ms ease;
}
.act-answer-container-vertical td:hover{
    background: lightblue;
}
.act-answer-container-vertical tr:nth-child(even){
    background: rgb(223, 223, 223);
}
.act-answer-container-horizontal{
    overflow-x: scroll;
}
.act-answer-container-horizontal table{
    border-collapse: collapse;
    margin: 0.5rem 0.5rem 1rem 0.5rem;
}
.act-answer-container-horizontal td{
    border: 1px black solid;
    min-width: 1.45rem;
    text-align: center;
    transition: 200ms ease;
}
.act-answer-container-horizontal td:nth-child(even){
    background: rgb(223, 223, 223);
}
.act-answer-container-horizontal td:hover{
    background: lightblue;
}
.orange-square{
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: tomato;
    margin-right: 0.5rem;
}
.act-history-container{
    padding: 2rem 0 2rem 0;
    overflow-x: scroll;
}
.act-history-table{
    border-collapse: collapse;
}
.act-history-table td{
    padding: 0 0.5rem 0 0.25rem;
}
.act-history-table td:first-child{
    border-right: 1px black solid;
}
.act-history-td{
    border-top: 1px solid black;
}