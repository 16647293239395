.book-club-title{
    font-size: 4rem;
    font-weight: bold;
    margin: 1rem;
}

.book-club-content-container{
    display: flex;
    flex-direction: row;
    /* border: 2px black solid; */
    justify-content: center;
    padding: 1rem 0 2rem 0;
}

.book-club-content-title{
    width: min(40%, 50rem);
    font-size: 4rem;
    padding-top: 1rem;
    position: relative;
    text-align: center;
    /* border: 2px red solid; */
}

.book-club-content-title.toggle-on{
    display: none;
}
.book-club-content-title.toggle-off{
    display:block;
}

.book-club-title-background{
    z-index: -1;
    width: 20rem;
    height: 9rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(5rem);
}

.rose{background: #ff8fab;}
.violet{background: #e4c1f9;}
.green{background: #baec69;}

.book-club-content{
    width: min(58%, 60rem);
    /* border: 2px green solid; */
    text-align: left;
    padding: 0 1rem 0 1rem;
    font-size: 1.25rem;
}

.book-club-divider{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0 0.5rem 0;
    margin: 4rem 1rem 4rem 1rem;
}

.book-club-divider::before,
.book-club-divider::after{
    content:"";
    display: block;
    height: 2px;
    flex-grow: 1;
    background: black;
}

.book-club-divider img{
    width: 1rem;
    aspect-ratio: 1;
    margin: 0 1rem 0 1rem;
}

.orange-divider-circle{
    width: 0.5rem;
    aspect-ratio: 1;
    background: orange;
    border-radius: 50%;
}

.teal-divider-circle{
    width: 0.5rem;
    aspect-ratio: 1;
    background: teal;
    border-radius: 50%;
}

@media screen and (max-width: 750px){
    .book-club-content-title{
        font-size: 2.25rem;
        font-weight: bold;
        width: 100%;
    }
    .book-club-content{
        font-size: 1rem;
        width: 100%;
    }
    .book-club-title{
        font-size: 3rem;
    }
    .book-club-content-title.toggle-on{
        display: block;
    }
    .book-club-content-title.toggle-off{
        display: none;
    }
    .book-club-content-container{
        flex-direction: column;
    }
    .book-club-title-background{
        transform: translateX(-50%) rotate(30deg);
    }
}