.subject-list-container{
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(45vw,11rem), 1fr));
    text-align: left;
    align-self: flex-end;
}

.subject{
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 0.4rem;
    user-select: none;
}