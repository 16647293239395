.main-side-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.main-container{
    flex-grow: 1;
    max-width: 60rem;
    justify-content: center;
    text-align: left;
    padding: 1rem;
}
.side-container{
    width: 13rem;
}
.main-container h2{
    text-decoration: underline;
}
.img-container{
    width: 100%;
}
.SAT-compare-table{
    width: min(50rem, 100%);
    height: auto;
    margin: 1rem 0 1rem 0;
}
.module-example{
    width: 100%;
    height: auto;
    margin: 1rem 0 1rem 0;
}
.module-key{
    height: 4rem;
    width: auto;
}
.adaptive-testing-steps{
    list-style: upper-roman;
}
.adaptive-testing-steps li{
    margin-top: 1rem;
}
.digital-sat-summary li{
    margin-top: 0.5rem;
}
@media screen and (max-width: 700px){
    .SAT-compare-table{
        width: 100%;
        height: auto;
    }
}
.sticky-container{
    position: sticky;
    top: 3.5rem;
}
.sidebar-button-container li{
    padding: 0 0 0.5rem 0;
}
.sidebar-button{
    padding: 0.2rem;
    background: transparent;
    border-radius: 4px;
    transition: 0.3s;
}
.sidebar-button:hover{
    background: black;
    color: white;
    transition: 0.5s;
}