.code-demo-container{
    width: 100%;
}
.code-demo-container span{
    display: block;
    font-family: 'Courier New', Courier, monospace;
}
.output-container{
    height: 10rem;
    overflow-y: scroll;
    background: rgb(177, 177, 177);
    font-family: 'Courier New', Courier, monospace;
}