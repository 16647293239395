.course-card{
    overflow: hidden;
    display: flex;
    opacity: 0;
    filter: blur(5px);
    transform: translateY(30px);
    background-color: rgb(196, 224, 206);
    box-shadow: 0px 2px 5px gray;
    border-radius: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 200ms ease-in, opacity 1s;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
  @media screen and (min-width: 992px){
    .course-card:hover{
      transform: translateY(-3px);
      box-shadow: 0px 2px 5px rgb(66, 66, 66);
    }
  }
  .course-card-title{
    padding: 0.5rem;
  }
  .course-card-description{
    padding: 0 1rem;
    text-align: left;
  }
  .course-card-link{
    text-decoration: none;
    padding: 0.25rem 0.25rem;
    color: lightcoral;
  }
  .course-card-link:hover{
    text-decoration: underline;
    font-size:larger;
  }

  .course-card.visible{
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }