.bottom-menu-container{
    height: 3rem;
    width: 100%;
    position: fixed;
    display: flex;
    flex-direction: row;
    bottom: 0;
    left: 0;
    background: #f8f4e7;
    z-index: 1;
}
.day-selector-bar{
    display: flex;
    width: 100%;
}
.day-selector{
    width: calc(100%/7);
    transition: 200ms ease;
}
.day-selector:hover{
    background: rgba(197, 231, 242, 0.5);
}
.bottom-menu-button{
    border: 1px black solid;
    cursor: pointer;
    padding-top: 0.25rem;
    margin-left: 0.25rem;
    width: calc(90%/4);
    border-radius: 5px;
    transition: 300ms ease;
}
.bottom-menu-button:hover{
    background: rgba(221, 221, 221, 0.5);
}
.scheduler-backdrop{
    background: rgba(0, 0, 0, 0.648);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 2;
}
.scheduler-small-popup-container{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    max-height: 80vh;
    background: white;
    border-radius: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
}

.scheduler-calendar-container-small{
    width: 95%;
    margin: 0 auto;
}
.scheduler-calendar-small{
    width: 100%;
}
.scheduler-calendar-date-small{
    position: relative;
    width: calc(100%/7);
    height: 2.25rem;
}
.calendar-blue-circle-small{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    background: rgba(0, 72, 255, 0.5);
    cursor: pointer;
}
.calendar-lesson-count-small{
    position: absolute;
    top:-0.25rem;
    right:-0.25rem;
    width:1rem;
    height:1rem;
    border-radius:50%;
    background: rgba(255, 17, 17, 0.9);
    font-size:0.75rem;
    align-items: center;
    justify-content: center;
}


.teacher-toggle-calendar-button{
    height: 3rem;
    border: 1px black solid;
    border-radius: 5px;
    padding: 0.25rem 1rem 0 1rem;
    position: fixed;
    bottom:0.25rem;
    right: 2.5rem;  
    cursor: pointer;
    background: #f8f4e7;
    transition: 200ms ease;
}
.teacher-toggle-calendar-button:hover{
    background: lightblue;
}