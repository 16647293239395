.choose-questions-wrapper{
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 1rem 2rem 1rem;
    gap: 1rem;
    height: 80vh;
    justify-content: center;
}
.assigned-homework-container{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: 0.5rem;
    width: min(30rem, 100%);
    height: 100%;
    background: rgb(205, 205, 205);
}
.unassigned-homework-container{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: 0.5rem;
    width: min(30rem, 100%);
    height: 100%;
    background: rgb(205, 205, 205);
}
.assigned-homework-container::-webkit-scrollbar{
    width: 0.4rem;
}
.assigned-homework-container::-webkit-scrollbar-thumb{
    background: #333;
}
.unassigned-homework-container::-webkit-scrollbar{
    width: 0.4rem;
}
.unassigned-homework-container::-webkit-scrollbar-thumb{
    background: #333;
}
.magnify-icon{
    width: 0.8rem;
    aspect-ratio: 1;
    cursor: pointer;
    margin-left: 0.5rem;
}
.homework-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:rgba(27, 27, 27, 0.4);
    z-index: 10;
}
.homework-info-container{
    position: relative;
    top: 3rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}
.homework-details-container{
    background: white;
    width: min(25rem, 100%);
}
.homework-image{
    width: min(25rem, 100%);
}